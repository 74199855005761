<template>
  <div class="big">
    <div class="center">
      <img src="../assets/submit-success.png" alt="" />
      <p class="title1">作品提交成功！</p>
      <p class="title2">扫码关注Hikoon Music公众号获取活动后续消息。</p>
      <div><img class="qrcode" src="../assets/qrcodework.png" alt="" /></div>
      <button @click="$router.replace('/activity')">返回活动中心</button>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
.big {
  width: 1120px;
  overflow: hidden;
  height: 800px;
  background: #ffffff;
  margin: 0px auto;
  margin-top: 44px;
  margin-bottom: 48px;
  .center {
    margin-top: 115px;
    text-align: center;
    img {
      width: 105px;
      height: 80px;
    }
    .title1 {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: CENTER;
      color: #222222;
      margin-top: 24px;
    }
    .title2 {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #666660;
      margin-top: 12px;
    }
    div{
        margin-top:20px;
      .qrcode{
        width:138px;
        height:138px;
    }
    }
   
    button {
      margin-top:64px;
      border: none;
      width: 202px;
      height: 40px;
      background: #ff004d;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align:center;
      color: #ffffff;
      line-height: 14px;
    }
  }
}
</style>